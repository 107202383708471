import React, { FC } from 'react';
import classNames from 'classnames';

import Typography from 'components/elements/Typography';
import ScrollWrapper from 'components/helpers/ScrollWrapper';

import { IPropsOrderedListItem } from './models';

import './OrderedListItem.scss';

const OrderedListItem: FC<IPropsOrderedListItem> = ({ data, className }) => (
  <li data-testid="OrderedListItem" className={classNames('ordered-list-item', className)}>
    <ScrollWrapper name={data.anchorTarget || ''}>
      <Typography data={data.text} customClass="ordered-list-item__text" />
    </ScrollWrapper>
  </li>
);

export default OrderedListItem;
