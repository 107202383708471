import React, { FC, useCallback, useState } from 'react';
import classNames from 'classnames';

import Button from 'components/elements/Button';
import OrderedListItem from 'components/elements/OrderedListItem';
import Typography from 'components/elements/Typography';
import ScrollWrapper from 'components/helpers/ScrollWrapper';

import { IPropsOrderedList } from './models';

import './OrderedList.scss';

const OrderedList: FC<IPropsOrderedList> = ({ data: [data] }) => {
  const { items, title, seeMoreCta, itemsInView, navigationName } = data;
  const [isHidden, setIsHidden] = useState(itemsInView && itemsInView > 1);

  const handleButtonClick = useCallback(() => setIsHidden(false), []);

  return (
    <ScrollWrapper name={navigationName || ''}>
      <div data-testid="OrderedList" className="ordered-list">
        <Typography data={title} customClass="ordered-list__title" />
        <ol
          className={classNames('ordered-list__items', {
            'ordered-list__overflowed': seeMoreCta && isHidden,
          })}
        >
          {items.map((item, index) => (
            <OrderedListItem
              key={item.text[0].text}
              data={item}
              className={classNames({
                'ordered-list-item__hidden': isHidden && itemsInView && index + 1 > itemsInView,
              })}
            />
          ))}
        </ol>
        {seeMoreCta && isHidden ? (
          <Button
            data={seeMoreCta}
            clickHandler={handleButtonClick}
            className="ordered-list__cta"
          />
        ) : null}
      </div>
    </ScrollWrapper>
  );
};

export default OrderedList;
