import React, { FC } from 'react';

import { textNoSpaces } from 'utils/stringUtils/stringUtils';

import { IPropsScrollWrapper } from './models';

import './ScrollWrapper.scss';

const ScrollWrapper: FC<IPropsScrollWrapper> = ({ name, children }) =>
  name ? (
    <div data-testid="ScrollWrapper" className="scroll-wrapper">
      <a
        key={name}
        id={textNoSpaces(name.replace('#', ''), '_')}
        href={`#${textNoSpaces(name, '_')}`}
        aria-label={`${textNoSpaces(name, '_')}`}
        className="scroll-wrapper__anchor"
      >
        {' '}
      </a>
      {children}
    </div>
  ) : (
    children
  );

export default ScrollWrapper;
