import React, { FC } from 'react';
import classNames from 'classnames';

import Typography from 'components/elements/Typography';
import DynamicImage from 'components/helpers/DynamicImage';
import GatsbyImage from 'components/helpers/GatsbyImage';

import { IPropsImageWithText } from './models';

import './ImageWithText.scss';

const ImageWithText: FC<IPropsImageWithText> = ({ data: [data] }) => {
  const { text, textBottom, imageSvg, image } = data;

  return (
    <div data-testid="ImageWithText" className="image-with-text">
      <div className="image-with-text__content">
        <div
          className={classNames('image-with-text__left', {
            'image-with-text__left--absolute': !imageSvg?.svg,
          })}
        >
          {imageSvg?.svg ? (
            <GatsbyImage image={imageSvg} className="image-with-text__image-svg" />
          ) : null}
          {image && !imageSvg?.svg ? (
            <DynamicImage image={image} className="image-with-text__image" />
          ) : null}
        </div>
        <div className="image-with-text__right">
          <Typography data={text} customClass="image-with-text__text" />
        </div>
      </div>
      {textBottom?.length ? (
        <Typography data={textBottom} customClass="image-with-text__text-bottom" />
      ) : null}
    </div>
  );
};

export default ImageWithText;
