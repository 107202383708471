import React, { FC } from 'react';

import Button from 'components/elements/Button';
import ImageWithText from 'components/elements/ImageWithText';
import Typography from 'components/elements/Typography';
import BaseSection from 'components/helpers/BaseSection';

import { IPropsHowItWorks } from './models';

import './HowItWorks.scss';
import './ShadowHowItWorks.scss';

const HowItWorks: FC<IPropsHowItWorks> = ({ data: [data] }) => {
  const { title, text, ctaButton, imageWithText, sectionSettings } = data;

  return (
    <BaseSection data={sectionSettings}>
      <div data-testid="HowItWorks" className="how-it-works">
        <Typography data={title} customClass="how-it-works__title" />
        <div className="how-it-works__content">
          <div className="how-it-works__right">
            <Typography data={text} customClass="how-it-works__text" />
            {ctaButton?.length ? (
              <div className="how-it-works__cta">
                <Button data={ctaButton} />
              </div>
            ) : null}
          </div>
          <div className="how-it-works__left">
            <ImageWithText data={imageWithText} />
          </div>
        </div>
      </div>
    </BaseSection>
  );
};

export default HowItWorks;
